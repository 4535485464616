import React from 'react'
import PublicPage from './PublicPage'

const ExtendsWithSubRoutes = [

    {
        path: "/en/service/_module_demo_en",
        component: PublicPage
    },
    {
        path: "/it/servizio/_module_demo_it",
        component: PublicPage
    }

]

export default ExtendsWithSubRoutes