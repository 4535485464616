import React from 'react';
import { Helmet } from "react-helmet";
import Router from './components/router'
import { withTranslation } from 'react-i18next';

function App({data, i18n, t}) { 
    
	return (
        <div className="App">
            {
                (process.env.NODE_ENV !== 'production') && (
                    <Helmet meta={[
                        { name: 'robots', content: 'noindex, nofollow'}
                    ]}/>
                )

            }
			<Helmet>
				<meta charSet="utf-8" />
			</Helmet>

			<Router i18n={i18n} t={t} />
		</div>
	);
}

export default withTranslation()(App)
