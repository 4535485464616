import React, { useEffect } from 'react'
import { Helmet } from "react-helmet";
import { withTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComment } from '@fortawesome/free-solid-svg-icons'

import Header from '../components/Header'
import Footer from '../components/Footer'

import Faqs from '../components/Faqs'
import Contact from '../components/Contact'

import '../scss/assistance.scss'

const Assistance = props => {

    const { t } = props;

    useEffect(() => {

        const bodyRoot = document.getElementsByTagName('body');
        bodyRoot[0].classList.add('assistance')

        return () => {
            bodyRoot[0].classList.remove('assistance')
        }

    })

    return ( 
        <main className="section assistance">

            <Helmet>
                <meta charSet="utf-8" />
                <title>{ t('sections.assistance.page.title') }</title>
                <meta name="description" content={ t('sections.assistance.page.description') } />
                <link rel="canonical" href={ t('sections.assistance.page.canonical') } />
            </Helmet>

            <Header pagename="assistance" />

            <div id="Assistance" className="container">
                <div className="section primary s_faq">
                    <div className="section faq">
                        <h2> 
                            <FontAwesomeIcon icon={faComment} /> { t('sections.assistance.faq.title') }
                        </h2>
                        <Faqs/>
                    </div>
                </div>
                <div className="section primary s_contact">
                    <div className="section contact">
                        <div>
                            <h2>{ t('sections.assistance.contact.title') }</h2>
                            <p>{ t('sections.assistance.contact.description') }</p>
                            <Contact/>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />

        </main>
    )
}

export default withTranslation()(Assistance)