const lang_allowed = ['it', 'en']
const lang_default = 'it'
const lang_allowed_regex = '/:lang(it|en)?';
const lang_it_regex = '/:lang(|it)?';
const lang_en_regex = '/:lang(|en)?';

exports.lang_allowed = lang_allowed
exports.lang_default = lang_default
exports.lang_allowed_regex = lang_allowed_regex
exports.lang_it_regex = lang_it_regex
exports.lang_en_regex = lang_en_regex