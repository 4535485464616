import React from 'react'
import PublicPage from './PublicPage'

const ExtendsWithSubRoutes = [

    {
        path: "/en/service/kitchen-order-ticket",
        component: PublicPage
    },
    {
        path: "/it/servizio/gestione-comande-cucina",
        component: PublicPage
    }

]

export default ExtendsWithSubRoutes