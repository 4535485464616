import React from 'react'
import { Helmet } from "react-helmet";

import { graphql } from '@apollo/client/react/hoc';
import {flowRight as compose} from 'lodash'

import Header from '@betwixt/web/src/components/Header';
import Footer from '@betwixt/web/src/components/Footer';
import PublicPage_Button_Purchase from '@betwixt/common/dist/components/PublicPage_Button_Purchase'

import { GET_MODULE } from '@betwixt/common/src/graphql/modules'
import { getModulePath } from '@betwixt/common/src/utils/modules'

import '@betwixt/common/src/scss/publicPage.scss'
import '../../client/scss/publicPage.scss'

const image_header = require('../../client/assets/images/betwixt-gestione-comande-cucina.png').default
const image_completed = require('../../client/assets/images/completed.svg')
const image_menu_orderable = require('../../client/assets/images/betwixt-gestione-menu-ordinabile.png').default
const image_hours_for_reservations = require('../../client/assets/images/betwixt-hours-for-reservations.png').default
const image_order_calendar = require('../../client/assets/images/betwixt-order-calendar.png').default
const image_preparation_time = require('../../client/assets/images/betwixt-preparation-time.png').default

const PublicPage = props => {

    const { Module, t, _t, i18n } = props;

    return (
        <main id="PublicPage" role="main">

            <Helmet>
                <meta charSet="utf-8" />
                <title>{ _t('PublicPage.page.title') }</title>
                <meta name="description" content={ _t('PublicPage.page.description') } />
                <link rel="canonical" href={ getModulePath({ moduleId: Module ? Module.moduleId : false, module: Module, lang: i18n.language }) } />
            </Helmet>

            <Header logoClass="twixtWhite" />

            <div className="container">
                <div className="productCard productCard_KOT">
                    <header>
                        <h1 dangerouslySetInnerHTML={{ __html: _t('routes.public_page.header.title') }} />
                        <p dangerouslySetInnerHTML={{ __html:  _t('routes.public_page.header.description') }} />
                        <img src={image_header} />
                    </header>

                    <div className="content_row_top" 
                         dangerouslySetInnerHTML={{ __html: _t('routes.public_page.content_row_top.text') }} />
                         
                    <div className="content_row_top_completed">
                        <img height={70} src={image_completed} />
                        { _t('routes.public_page.content_row_top.completed') }
                    </div>

                    <div className="box-mm-title" 
                         dangerouslySetInnerHTML={{ __html: _t('routes.public_page.content_row_top.text_bottom') }} />

                    <div className="box-mm">
                        <div className="content">
                            <h3 dangerouslySetInnerHTML={{ __html: _t('routes.public_page.box_mm.title') }} />
                            <p dangerouslySetInnerHTML={{ __html: _t('routes.public_page.box_mm.description') }} />
                        </div>
                        <img src={image_menu_orderable} />
                    </div>
                    <p className="box-mm-footer" 
                       dangerouslySetInnerHTML={{ __html: _t('routes.public_page.box_mm.info') }} />

                    <div className="box_details">
                        <header dangerouslySetInnerHTML={{ __html: _t('routes.public_page.box_details.title') }} />
                        <div className="content">
                            <div className="box box-time">
                                <div className="title" dangerouslySetInnerHTML={{ __html: _t('routes.public_page.box_details.time.title') }} />
                                <div className="description" dangerouslySetInnerHTML={{ __html: _t('routes.public_page.box_details.time.description') }} />
                                <img src={image_hours_for_reservations} />
                            </div>
                            <div className="box box-calendar">
                                <div className="title" dangerouslySetInnerHTML={{ __html: _t('routes.public_page.box_details.calendar.title') }} />
                                <div className="description" dangerouslySetInnerHTML={{ __html: _t('routes.public_page.box_details.calendar.description') }} />
                                <img src={image_order_calendar} />
                            </div>
                            <div className="box box-preparation_time">
                                <div className="title" dangerouslySetInnerHTML={{ __html: _t('routes.public_page.box_details.preparation_time.title') }} />
                                <div className="description" dangerouslySetInnerHTML={{ __html: _t('routes.public_page.box_details.preparation_time.description') }} />
                                <img src={image_preparation_time} />
                            </div>
                        </div>
                    </div>

                    <PublicPage_Button_Purchase viewType="full" Module={Module} t={t} />

                </div>
            </div>

            <Footer Module={Module} />

        </main>
    )
}

export default compose(
    graphql(GET_MODULE, {
        options: {
            variables: { moduleId: 'kitchen-order-ticket' }
        },
        props: ({ data: { Module } }) => ({ Module })
    })
)(PublicPage)