import React from 'react'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'

import { getModulePath } from '@betwixt/common/src/utils/modules'

const LanguageSelector = props => {

    const { isModulesPage, Module, history, i18n, t } = props

    const getModulesString = (lang) => lang === 'it' ? 'servizi' : lang === 'en' ? 'services' : ''
    
    const getURL = (new_lang) => {
        if (isModulesPage) {
            let pathname = history.location.pathname 
                pathname = pathname.split('/')
            if (pathname.length > 2) { 
                // "/en/modules" > "[],[en],[modules]"
                pathname[1] = new_lang
                pathname[2] = getModulesString(new_lang)
            } else { 
                // "/modules" > "[],[modules]"
                pathname[1] = getModulesString(new_lang)
            }
            pathname = pathname.toString().replace(/,/g, '/')
            return `${global.gConfig.protocol}://${global.gConfig.sections.web.hostname}${pathname}`
        } else
        if (Module) {
            return getModulePath({ moduleId: Module.moduleId, module: Module, lang: new_lang })
        } 
    }

    return (
        <div id="languageSelector">
            <span className="label">Change language</span>
            <a href={ getURL('it') }> it </a>
            <a href={ getURL('en') }> en </a>
        </div>
    );
}

export default withRouter(LanguageSelector)