import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'

import LanguageSelector from './LanguageSelector'
import Logo from '@betwixt/common/src/assets/images/logo.svg'
import '@betwixt/common/src/scss/footer.scss';

const Footer = props => {

    const { isModulesPage, Module, i18n, t } = props
    
    return (
        <div id="footer" className="container">
            <div>
                <a href={`${global.gConfig.protocol}://${global.gConfig.sections.web.hostname}`}> 
                    <Logo className="logo" />
                </a>
            </div>
            <div className="container-menu">
                <nav> 
                    <LanguageSelector isModulesPage={isModulesPage} Module={Module} i18n={i18n} t={t} /> 
                </nav>
                <nav>
                    <a href={`${global.gConfig.protocol}://${global.gConfig.sections.web.hostname}/${i18n.language}/privacy-policy`}> 
                        Privacy Policy
                    </a>
                    <a href={`${global.gConfig.protocol}://${global.gConfig.sections.web.hostname}/${i18n.language}/cookie-policy`}> 
                        Cookie Policy
                    </a>
                </nav>
            </div>
        </div>
    )
}

export default withTranslation()(Footer)