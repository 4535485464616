
import React, { useEffect } from 'react'

const UserPublicPage_scripts = props => { 

    const useScript = url => {
        useEffect(() => {
            const script = document.createElement('script');
        
            script.src = url;
            script.async = true;
        
            document.body.appendChild(script);
        
            return () => {
                document.body.removeChild(script);
            }
        }, [url]);
    };
    
    const useStyle = url => {
        useEffect(() => {
            const style = document.createElement('link');
            
            style.rel = 'stylesheet'
            style.href = url;

            document.head.appendChild(style);
        
            return () => {
                document.head.removeChild(style);
            }
        }, [url]);
    };

    const filename = global.gConfig.config_id === 'production' ? '' : `--${global.gConfig.config_id}`

    useStyle(`${global.gConfig.protocol}://${global.gConfig.sections.web.hostname}/cdn/mm_widget${filename}.css`);
    useScript(`${global.gConfig.protocol}://${global.gConfig.sections.web.hostname}/cdn/mm_widget${filename}.js`);

    return ('')
}
export default UserPublicPage_scripts