import React, { useState, useEffect, Fragment } from 'react'
import { Helmet } from "react-helmet";

import { graphql, withApollo } from '@apollo/client/react/hoc'
import {flowRight as compose} from 'lodash'

import Header from '@betwixt/web/src/components/Header';
import Footer from '@betwixt/web/src/components/Footer';

import { GET_MODULE } from '@betwixt/common/src/graphql/modules'
import { formatPrice } from '@betwixt/common'

import '@betwixt/common/src/scss/publicPage.scss'
import '../../client/scss/landingApi.scss'

const PublicPage = props => {

    const { Module, t, _t, i18n } = props;

    return (
        <main id="PublicPage" role="main">

            <Helmet>
                <meta charSet="utf-8" />
                <title>{ _t('PublicPage.page.title') }</title>
                <meta name="description" content={ _t('PublicPage.page.description') } />
                <link rel="canonical" href={ _t('PublicPage.page.canonical') } />
            </Helmet>

            <Header logoClass="twixtWhite" />

            <div className="container">
                <div className="productCard productCard_MM_API">
                    <header>
                        <h1>PublicPage Api</h1>
                    </header>
                </div>
            </div>

            <Footer Module={Module} />

        </main>
    )
}

export default compose(
    graphql(GET_MODULE, {
        options: {
            variables: { moduleId: 'menu-management' }
        },
        props: ({ data: { Module } }) => ({ Module })
    })
)(PublicPage)