import React, { useState, useEffect, Fragment } from 'react'

import { formatPrice } from '@betwixt/common'

import '../scss/PublicPage_Button_Purchase.scss'

const PublicPage_Button_Purchase = props => {

    const { viewType, Module, price_info_extra, t } = props

    const [price, setPrice] = useState(undefined)
    const [moduleId, setModuleId] = useState(undefined)

    useEffect(() => {
        if (Module) {
            const plans = Module.subscription.plans;

            if (plans.length > 1) {
                const primaryPlan = plans.find( plan => plan && plan.active && plan.hasOwnProperty('metadata') && plan.metadata.primary == "true" )
                if (primaryPlan)
                    setPrice(primaryPlan)
            } else 
                setPrice(plans[0])

            setModuleId(Module.moduleId)
        }
    }, [Module])

    const getURL = () => `${global.gConfig.protocol}://${global.gConfig.sections.my.hostname}/signup?goto=shop&id=${moduleId}`
    
    if (viewType === 'minimal')
        return (
            <div className="cta">
                <a className="try_now_cta" href={getURL()}> 
                    { t('modules.price.cta') } 
                </a>
                {
                    price && price.trial_period_days && (
                        <p dangerouslySetInnerHTML={{ __html: `${price.trial_period_days} ${ t('modules.price.promo') }`}} />
                    )
                }
            </div>
        )

    if (viewType === 'full')
        return (
            <div className="section-price">

                <a className="try_now_cta" href={getURL()}> 
                    { t('modules.price.cta') } 
                </a>
                
                {
                    price && price.metadata && price.metadata.base_price && (
                        <p className="base_price">{ formatPrice(Number(price.metadata.base_price)) }</p>
                    )
                }

                <div className="price-block">
                    {
                        price && (
                            <Fragment>
                                <div className="price">{ formatPrice(price.amount) }</div>
                                <div className="info">{ t(`modules.price.interval.${price.interval}`) }</div>
                                <div className="extra">{ price_info_extra }</div>
                            </Fragment>
                        )
                    }
                </div>

                {
                    price && price.trial_period_days && (
                        <p dangerouslySetInnerHTML={{ __html: `${price.trial_period_days} ${ t('modules.price.promo') }`}} />
                    )
                }

            </div>
        )

}
export default PublicPage_Button_Purchase