import React, { Component } from 'react'

import Header from '@betwixt/web/src/components/Header';
import Footer from '@betwixt/web/src/components/Footer';
import Loading from '@betwixt/common/dist/components/Loading'

class Public extends Component {

    render() {

        return ( 
            <main id="NoMatch" role="main">
                    
                <Header logoClass="twixtWhite" />

                <Loading />

                <Footer />

            </main>
        )
    }

}

export default Public