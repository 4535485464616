import React, { Component } from 'react'
import { Helmet } from "react-helmet";
import { withTranslation } from 'react-i18next';

import Loading from '@betwixt/common/dist/components/Loading'
import Header from '../components/Header'
import Footer from '../components/Footer'

import '../scss/privacy-cookie-policy.scss'

class PrivacyPolicy extends Component {

    constructor() {
        super()

        this.state = {
            privacyPolicy: ''
        }
    }

    componentDidMount = () => {
        const URLPrivacyPolicy = "https://www.iubenda.com/api/privacy-policy/62075933/no-markup";

        window.dataLayer.push({
            event: 'pageview'
        });

        fetch(URLPrivacyPolicy)
        .then(res => res.json())
        .then(data => {
            if (data.success)
                this.setState({ privacyPolicy: data.content })
        })
    }

    render() {
        const { t } = this.props;

        if (!this.state.privacyPolicy) {
            return <Loading />
        }
        
        return ( 
            <main className="section">

                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Privacy Policy Betwixt</title>
                    <meta name="description" content={ t('sections.welcome.page.description') } />
                    <link rel="canonical" href={ t('sections.privacypolicy.canonical') } />
                </Helmet>

                <Header pagename="modules" logoClass="twixtWhite" />

                <div id="PrivacyPolicy" className="container">
                    <div className="section primary" 
                        dangerouslySetInnerHTML={{ __html: this.state.privacyPolicy }} 
                        />
                </div>

                <Footer />

            </main>
        )
    }
}

export default withTranslation()(PrivacyPolicy)