import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

import "../scss/welcome.scss";

import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

import Logo from "@betwixt/common/src/assets/images/logo.svg";

const particlesInit = async (main) => {
    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(main);
};

class Welcome extends Component {
    componentDidMount() {
        document.body.classList.add("darkMode");
    }

    componentWillUnmount() {
        document.body.classList.remove("darkMode");
    }

    render() {
        const { i18n, t } = this.props;

        const moduleSection_url =
            i18n.language === "it" ? "/it/servizi" : "/en/services";

        return (
            <main id="WelcomePage" role="main">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{t("sections.welcome.page.title")}</title>
                    <meta
                        name="description"
                        content={t("sections.welcome.page.description")}
                    />
                    <link
                        rel="canonical"
                        href={t("sections.welcome.page.canonical")}
                    />
                </Helmet>

                <div className="content">
                    <Logo id="logo" className="beWhite" />

                    <div className="csc">
                        <h1 className="slogan">
                            {t("sections.welcome.slogan")}
                        </h1>
                        <p>{t("sections.welcome.description")}</p>
                        <div className="cta">
                            <Link
                                className="btn btn-default mr-3 mb-1"
                                to={moduleSection_url}
                            >
                                {" "}
                                {t("sections.welcome.cta.explore")}{" "}
                            </Link>
                            <a
                                className="btn-login"
                                href={`${global.gConfig.protocol}://${global.gConfig.sections.my.hostname}/login`}
                            >
                                {" "}
                                {t("sections.welcome.cta.enter")}{" "}
                            </a>
                        </div>
                    </div>
                </div>

                <Particles
                    id="tsparticles"
                    init={particlesInit}
                    options={{
                        particles: {
                            number: {
                                value: 100,
                                density: {
                                    enable: true,
                                    value_area: 800,
                                },
                            },
                            color: {
                                value: "#5a5858",
                            },
                            shape: {
                                type: "circle",
                                stroke: {
                                    width: 0,
                                    color: "#403f3f",
                                },
                                polygon: {
                                    nb_sides: 5,
                                },
                            },
                            opacity: {
                                value: 0.5,
                                random: true,
                                anim: {
                                    enable: true,
                                    speed: 3,
                                    opacity_min: 0.1,
                                    sync: false,
                                },
                            },
                            size: {
                                value: 4,
                                random: true,
                                anim: {
                                    enable: false,
                                    speed: 40,
                                    size_min: 0.1,
                                    sync: false,
                                },
                            },
                            line_linked: {
                                enable: true,
                                distance: 250,
                                color: "#403f3f",
                                opacity: 0.4,
                                width: 1,
                            },
                            move: {
                                enable: true,
                                speed: 1,
                                direction: "none",
                                random: false,
                                straight: false,
                                out_mode: "out",
                                attract: {
                                    enable: false,
                                    rotateX: 600,
                                    rotateY: 1200,
                                },
                            },
                        },
                        interactivity: {
                            detect_on: "canvas",
                            events: {
                                onhover: {
                                    enable: true,
                                    mode: "repulse",
                                },
                                onclick: {
                                    enable: false,
                                    mode: "push",
                                },
                                resize: true,
                            },
                            modes: {
                                grab: {
                                    distance: 400,
                                    line_linked: {
                                        opacity: 1,
                                    },
                                },
                                bubble: {
                                    distance: 400,
                                    size: 40,
                                    duration: 2,
                                    opacity: 0.8,
                                    speed: 3,
                                },
                                repulse: {
                                    distance: 200,
                                },
                                push: {
                                    particles_nb: 4,
                                },
                                remove: {
                                    particles_nb: 2,
                                },
                            },
                        },
                        retina_detect: true,
                    }}
                />
            </main>
        );
    }
}

export default withTranslation()(Welcome);
