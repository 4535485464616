import { gql } from '@apollo/client'

export const SEND_MAIL = gql`
    mutation sendAssistantMail(
        $username: String!
        $topic: String!
        $email: String!
        $message: String!
        $recaptcha: String!
    ){
        sendAssistantMail(
            username: $username
            topic: $topic
            email: $email
            message: $message
            recaptcha: $recaptcha
        ){
            ok
            errors{
                path
                message
            }
        }
    }
`
