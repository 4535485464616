import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';
import detector from 'i18next-browser-languagedetector';
import renderHTML from "react-render-html";

const i18n_config = require('@betwixt/common/src/utils/i18n')

const options = {
	detection: {
		// order and from where user language should be detected
		order: ['path'],
	  
		// keys or params to lookup language from
		lookupQuerystring: 'lang',
		//lookupCookie: 'i18next',
		//lookupLocalStorage: 'i18nextLng',
		lookupFromPathIndex: 0,
		lookupFromSubdomainIndex: 0,
	  
		// cache user language on
		//caches: ['localStorage', 'cookie'],
		//excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
	  
		// optional expire and domain for set cookie
		//cookieMinutes: 10,
		//cookieDomain: 'betwixtpro.com',
	  
		// optional htmlTag with lang attribute, the default is:
		htmlTag: (process && process.browser) ? document.documentElement : null
	},
	interpolation: {
		escapeValue: false, // not needed for react!!
		formatSeparator: ',',
		format: (value, format, lng) => {
			if (format === 'uppercase') return value.toUpperCase();
			return value;
		},
	},
	process: (value, key, options, translator) => {
		if (value.match(/^ *html: */))
		  return renderHTML(value.replace(/^ *html: */, ""));
		return value;
	},
	//lng: i18n_config.lang_default,
	//lngs: i18n_config.lang_allowed,
	whitelist: i18n_config.lang_allowed,
	fallbackLng: i18n_config.lang_allowed,
	preload: i18n_config.lang_allowed,
	load: 'languageOnly', // we only provide en, de -> no region specific locals like en-US, de-DE
	// have a common namespace used around the full app
	ns: ['translations'],
	defaultNS: 'translations',

	react: {
		useSuspense: false
	},

	//saveMissing: true,
	debug: false,
	useCookie: false,

	wait: true
	//wait: process && !process.release,
};

// for browser use xhr backend to load translations and browser lng detector
if (process && !process.release) {
	i18n
		.use(XHR)
		.use(detector)
		.use(initReactI18next)
}

// initialize if not already initialized
if (!i18n.isInitialized)
	i18n
		.use(detector)
		.init(options);

export default i18n;
