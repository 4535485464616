import React from 'react'
import { withTranslation } from 'react-i18next';

import '../scss/assistance.scss'

const Faqs = props => {

    const { t } = props;

    return ( 
        <ul className="Faqs">
            <li>
                <h3 dangerouslySetInnerHTML={{ __html: t('sections.assistance.faq.questions.q0.question') }} />
                <p dangerouslySetInnerHTML={{ __html: t('sections.assistance.faq.questions.q0.answer') }} />
            </li>
            <li>
                <h3 dangerouslySetInnerHTML={{ __html: t('sections.assistance.faq.questions.q0_1.question') }} />
                <p dangerouslySetInnerHTML={{ __html: t('sections.assistance.faq.questions.q0_1.answer') }} />
            </li>
            <li>
                <h3 dangerouslySetInnerHTML={{ __html: t('sections.assistance.faq.questions.q1.question') }} />
                <p dangerouslySetInnerHTML={{ __html: t('sections.assistance.faq.questions.q1.answer', { 
                    signup_url: `${global.gConfig.protocol}://${global.gConfig.sections.my.hostname}/signup` 
                })}} />
            </li>
            <li>
                <h3 dangerouslySetInnerHTML={{ __html: t('sections.assistance.faq.questions.q2.question') }} />
                <p dangerouslySetInnerHTML={{ __html: t('sections.assistance.faq.questions.q2.answer', { 
                    signup_url: `${global.gConfig.protocol}://${global.gConfig.sections.my.hostname}/signup`,
                    login_url: `${global.gConfig.protocol}://${global.gConfig.sections.my.hostname}/login` 
                })}} />
            </li>
            <li>
                <h3 dangerouslySetInnerHTML={{ __html: t('sections.assistance.faq.questions.q3.question') }} />
                <p dangerouslySetInnerHTML={{ __html: t('sections.assistance.faq.questions.q3.answer') }} />
            </li>
            <li>
                <h3 dangerouslySetInnerHTML={{ __html: t('sections.assistance.faq.questions.q4.question') }} />
                <p dangerouslySetInnerHTML={{ __html: t('sections.assistance.faq.questions.q4.answer') }} />
            </li>
            <li>
                <h3 dangerouslySetInnerHTML={{ __html: t('sections.assistance.faq.questions.q5.question') }} />
                <p dangerouslySetInnerHTML={{ __html: t('sections.assistance.faq.questions.q5.answer', { 
                    login_url: `${global.gConfig.protocol}://${global.gConfig.sections.my.hostname}/login`
                })}} />
            </li>
        </ul>
    )
}

export default withTranslation()(Faqs)