import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import Navs from './Navs'

import '@betwixt/common/dist/scss/header.scss'
import Logo from '@betwixt/common/src/assets/images/logo.svg'

class Header extends Component {

    constructor(props){
        super(props)

        this.maxWidthMobile = 768
        this.offsetTopSticky = 150

        this.toggleMenu = this.toggleMenu.bind(this)
        this.checkScrollY = this.checkScrollY.bind(this)
        this.check_Window_For_Resize = this.check_Window_For_Resize.bind(this)

        this.state = {
            headerInSticky: false,
            primaryMenu_Mobile_Opened: false
        }
    }

    componentDidMount(){
        if(!this.props.withoutSticky)
            window.addEventListener('scroll', this.checkScrollY)

        this.check_Window_For_Resize();
        window.addEventListener('resize', this.check_Window_For_Resize)
    }

    componentWillUnmount(){
        if(!this.props.withoutSticky)
            window.removeEventListener('scroll', this.checkScrollY)
        
        window.removeEventListener('resize', this.check_Window_For_Resize)
    }

    check_Window_For_Resize() {
        if( window.innerWidth > this.maxWidthMobile && this.state.primaryMenu_Mobile_Opened )
            this.toggleMenu()
    }

    checkScrollY(){
        const headerInSticky = window.scrollY > this.offsetTopSticky
        
        if (headerInSticky)
            document.body.classList.add('headerInSticky')
        else
            document.body.classList.remove('headerInSticky')

        const newState = Object.assign({}, this.state, {
            headerInSticky
        });
        this.setState(newState)
    }

    toggleMenu = () => { 
        const newState = Object.assign({}, this.state, {
            primaryMenu_Mobile_Opened: !this.state.primaryMenu_Mobile_Opened
        });
        this.setState(newState)
    }

    render() {
        const { pagename, withoutLogo, withoutNavs, logoClass, i18n } = this.props

        return (
            <header className={`container header ${ (this.state.headerInSticky) ? 'sticky' : '' } ${ this.state.primaryMenu_Mobile_Opened ? 'primaryMenu_Mobile_Opened' : '' }`} data-pagename={pagename}>
                <div className="container d-flex flex-row justify-content-between">
                    <a href={`${global.gConfig.protocol}://${global.gConfig.sections.web.hostname}/${i18n.language}`}>
                        <Logo id="logo" className={`${this.state.primaryMenu_Mobile_Opened ? 'beWhite' : ''} ${ logoClass ? logoClass : '' }`} />
                    </a>
                    
                    <Navs pagename={pagename} primaryMenu_Mobile_Opened={this.state.primaryMenu_Mobile_Opened} toggleMenu={ () => this.toggleMenu() } /> 
                </div>
            </header>
        )
    }
}

export default withTranslation()(Header)