import React, { Component, Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import { NavLink, withRouter } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { OAuth } from '@betwixt/common/src/utils/auth'
import { isMobile, updatePageData } from '@betwixt/common/src/utils/page'

import '@betwixt/common/src/scss/navs.scss'

const Navs = props => {

    const { primaryMenu_Mobile_Opened, toggleMenu, i18n, t } = props

    return (
        <Fragment>
            <div id="togglePrimaryMenu" className={(primaryMenu_Mobile_Opened) ? 'opened' : ''} onClick={() => toggleMenu() }>
                <div /> <div /> <div />
            </div>
            
            <nav id="primaryMenu" className={(primaryMenu_Mobile_Opened) ? 'opened' : ''}>
                {
                    <ul> 
                        <li className="explore-btn"> <NavLink to={i18n.language == 'it' ? '/it/servizi' : '/en/services'}> {t('sections.welcome.cta.explore')} </NavLink> </li> 
                        <li className="explore-btn"> <NavLink to={i18n.language == 'it' ? '/it/assistenza-servizi' : '/en/assistance-services'}> {t('sections.welcome.cta.faq')} </NavLink> </li> 
                        <li className="explore-btn"> <a href={`${global.gConfig.protocol}://${global.gConfig.sections.my.hostname}/signup`}> {t('sections.welcome.cta.signup')} </a> </li> 
                        <li id="btn-login"> <a href={`${global.gConfig.protocol}://${global.gConfig.sections.my.hostname}/login`}> {t('sections.welcome.cta.login')} </a> </li> 
                    </ul>
                }
            </nav>

            {
                primaryMenu_Mobile_Opened && (
                    <div id="darkflow" onClick={() => toggleMenu() }></div>
                )
            }

        </Fragment>
    )
}
export default withTranslation()(Navs)