const getPathMedia = () => {
    if (global.gConfig.config_id === 'development') {
        return `${global.gConfig.protocol}://${global.gConfig.sections.server.hostname}/media`
    } else {
        return `${global.gConfig.protocol}://${global.gConfig.sections.my.hostname}/media`
    }
}

const getFileMedia = async (filename, clientId) => {
    return `${getPathMedia()}/${clientId}/${filename}.jpg`;
}

const checkMediaIfExist = async (filename, clientId) => {
    if (!filename || !clientId)
        return false
        
    try {
        const file = await getFileMedia(filename, clientId)
        const check = await fetch(file, {
            method: 'HEAD',
            cache: 'no-cache'
        });
        return check.status === 200
    } catch (err) {
        return false
    }
}

exports.getPathMedia = getPathMedia;
exports.getFileMedia = getFileMedia;
exports.checkMediaIfExist = checkMediaIfExist;