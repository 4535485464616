import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import Loadable from 'react-loadable';

import { useSSR } from 'react-i18next';
import i18n from './i18n';

import { BrowserRouter } from "react-router-dom";

import { ApolloProvider } from '@apollo/client'
import Client from './Client'

import App from './App';

import { I18nextProvider } from 'react-i18next';

import TagManager from 'react-gtm-module';

import './scss/root.scss';

require('bootstrap/dist/css/bootstrap.min.css');
require('bootstrap/dist/js/bootstrap.bundle.min');
require('@betwixt/common/src/config/config.js')

const tagManagerArgs = {
    gtmId: global.gConfig.GTM.id,
    auth: global.gConfig.GTM.gtm_auth,
    preview: global.gConfig.GTM.gtm_preview
}

TagManager.initialize(tagManagerArgs)

const BaseApp = () => {
    useSSR(window.initialI18nStore, window.initialLanguage);
    return (
        <Suspense fallback={<div>loading...</div>}>

            <ApolloProvider client={Client}>
                <BrowserRouter>
                    <I18nextProvider i18n={ i18n }>
                        <App {...window.__APP_INITIAL_STATE__}/>
                    </I18nextProvider>
                </BrowserRouter>
            </ApolloProvider>

        </Suspense>
    );
}

Loadable.preloadReady().then(() => {
    ReactDOM.render(<BaseApp/>, document.getElementById('root'));
})

if (module.hot) {
    module.hot.accept();
}
