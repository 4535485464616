import React, { useEffect } from 'react'
import { Helmet } from "react-helmet";
import { withTranslation } from 'react-i18next';
import '../scss/modules.scss'

import { graphql } from '@apollo/client/react/hoc';
import { GET_MODULES } from '@betwixt/common/src/graphql/modules'

import Loading from '@betwixt/common/dist/components/Loading'
import Header from '../components/Header'
import Footer from '../components/Footer'

import { getModulePath } from '@betwixt/common/src/utils/modules'

const Modules = props => {

    const { t, i18n, Modules } = props;

    useEffect(() => {
        window.dataLayer.push({
            event: 'pageview'
        });
    }, [])

    const renderingListModules = () => {
        if (Modules) {
            return Modules.map((data, index) => {
                if (data.module && data.module.options && data.module.options.showInModulesList && data.module.options.showInModulesList != null) {
                    try {
                        const language = (i18n.language.indexOf('-') !== -1) ? (i18n.language.split('-'))[0] : i18n.language;
                        const resource = require(`@betwixt/common/dist/modules/${data.moduleId}/translation/${language}.json`)
                        const imageSmall = require(`@betwixt/common/dist/modules/${data.moduleId}/client/assets/images/thumbnail.jpg`).default
                        const modulePath = getModulePath({ moduleId: data.moduleId, module: data, lang: language })
                        i18n.addResourceBundle(language, data.moduleId, resource, true, true)
                        
                        return (
                            <div key={index} id={data.moduleId} className="row module">

                                <div className="col-md-5 col-12 moduleImage" style={{backgroundImage : `url(${imageSmall})`}}></div>

                                <div className="col-md-7 col-12 info">
                                    <h3> {i18n.getResource(language, data.moduleId, 'preview.title')} </h3>
                                    <p> {i18n.getResource(language, data.moduleId, 'preview.description.smart')} </p>
                                    <a className="cta cta-action" key={index.toString()} href={modulePath}> 
                                        { t('sections.modules.cta_more_details') }
                                    </a>
                                </div>

                            </div>
                        )
                    } catch (err) { 
                        console.log(err)
                    }
                }
            })
        } else {
            return <Loading />
        }
    }

    return ( 
        <main id="Modules" role="main">

            <Helmet>
                <meta charSet="utf-8" />
                <title>{t('sections.modules.page.title')}</title>
                <meta name="description" content={t('sections.modules.page.description')} />
                <link rel="canonical" href={ t('sections.modules.page.canonical') } />
            </Helmet>

            <Header pagename="modules" logoClass="twixtWhite" />

                <div className="container">
                    { renderingListModules() }
                </div>
            
            <Footer isModulesPage={true} i18n={i18n} />
            
        </main>
    )
}

export default 
    graphql(GET_MODULES, {
        props: ({ data: { Modules } }) => ({ Modules })
    })
(withTranslation()(Modules))