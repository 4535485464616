import React, { useState, Fragment } from 'react'
import { withTranslation } from 'react-i18next';

import { useMutation } from '@apollo/client'
import { SEND_MAIL } from '@betwixt/common/src/graphql/assistant'

import ReCAPTCHA from "react-google-recaptcha";

import '../scss/assistance.scss'

const Contact = props => {

    const { t } = props;

    const [username, setUsername] = useState('')
    const [email, setEmail] = useState('')
    const [topic, setTopic] = useState('')
    const [message, setMessage] = useState('')
    const [recaptcha,setRecaptcha] = useState(false)

    const [SendData, { data }] = useMutation(SEND_MAIL)

    const resetForm = () => {
        setUsername('');
        setTopic('')
        setMessage('')
    }

    const SubmitForm = (e) => {
        e.preventDefault();
        
        if (username !== '' && topic !== '' && message !== '') {
            SendData({ variables: { username, email, topic, message, recaptcha } });
            resetForm()
        } else {
            console.log("NO SEND")
        }
    }

    const SetRecaptcha = async (recaptchaValue) => {
        setRecaptcha(recaptchaValue)
    }

    console.log("data", data)

    return (
        <form className="Contact" onSubmit={e => SubmitForm(e)}>

            {
                data && data.sendAssistantMail.ok ? (
                    <div className="send-success">{ t('sections.assistance.contact.sendmail.ok') }</div>
                ) : (
                    <Fragment>
                        <div className="form-group">
                            <label>{ t('sections.assistance.contact.form.username.label') }</label>
                            <input name="username"
                                className="form-control"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                />
                        </div>

                        <div className="form-group">
                            <label>{ t('sections.assistance.contact.form.email.label') }</label>
                            <input name="email"
                                type="email"
                                className="form-control"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                />
                        </div>

                        <div className="form-group">
                            <label>{ t('sections.assistance.contact.form.topic.label') }</label>
                            <select name="service"
                                className="form-control"
                                onChange={(e) => setTopic(e.target.value)}
                                >
                                <option value=""></option>
                                <option value={ t('sections.assistance.contact.form.topic.options.mm') }>
                                    { t('sections.assistance.contact.form.topic.options.mm') }
                                </option>
                                <option value={ t('sections.assistance.contact.form.topic.options.kot') }>
                                    { t('sections.assistance.contact.form.topic.options.kot') }
                                </option>
                                <option value={ t('sections.assistance.contact.form.topic.options.kot') }>
                                    { t('sections.assistance.contact.form.topic.options.others') }
                                </option>
                            </select>
                        </div>

                        <div className="form-group">
                            <label>{ t('sections.assistance.contact.form.message.label') }</label>
                            <textarea className="form-control" defaultValue={message} onChange={(e) => setMessage(e.target.value)} />
                        </div>

                        <ReCAPTCHA
                            sitekey={global.gConfig.google.reCAPTCHA}
                            onChange={SetRecaptcha}
                            className="mb-3"
                        />

                        <button type="submit" className="btn btn-warning w-100">{ t('sections.assistance.contact.form.submit') }</button>

                    </Fragment>
                )
            }

            
        </form>
    )
}

export default withTranslation()(Contact)