import React, { Component } from 'react'
import { Helmet } from "react-helmet";
import { withTranslation } from 'react-i18next';

import Loading from '@betwixt/common/dist/components/Loading'
import Header from '../components/Header'
import Footer from '../components/Footer'

import '../scss/privacy-cookie-policy.scss'

class CookiePolicy extends Component {

    constructor() {
        super()

        this.state = {
            cookiePolicy: ''
        }
    }

    componentDidMount = () => {
        const URLCookiePolicy = "https://www.iubenda.com/api/privacy-policy/62075933/cookie-policy/no-markup";

        window.dataLayer.push({
            event: 'pageview'
        });

        fetch(URLCookiePolicy)
        .then(res => res.json())
        .then(data => {
            if (data.success)
                this.setState({ cookiePolicy: data.content })
        })
    }

    render() {
        const { t } = this.props;

        if (!this.state.cookiePolicy) {
            return <Loading />
        }

        return ( 
            <main className="section">

                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Privacy Policy Betwixt</title>
                    <meta name="description" content={ t('sections.welcome.page.description') } />
                    <link rel="canonical" href={ t('sections.cookiepolicy.canonical') } />
                </Helmet>

                <Header pagename="modules" logoClass="twixtWhite" />

                <div id="CookiePolicy" className="container">
                    <div className="section primary" 
                        dangerouslySetInnerHTML={{ __html: this.state.cookiePolicy }} 
                        />
                </div>

                <Footer />

            </main>
        )
    }
}

export default withTranslation()(CookiePolicy)