import React from 'react'
import PublicPage from './PublicPage'
import PublicPageLanding from './PublicPageLanding'
import LandingApi from './LandingApi'
import LandingWidget from './LandingWidget'
import UserPublicPage from './UserPublicPage'

const ExtendsWithSubRoutes = [

    {
        path: "/en/service/menu-management/shop",
        component: PublicPage
    },
    {
        path: "/it/servizio/gestione-menu/shop",
        component: PublicPage
    },

    {
        path: "/en/service/menu-management/personal-page",
        component: PublicPageLanding
    },
    {
        path: "/it/servizio/gestione-menu/pagina-personale",
        component: PublicPageLanding
    },

    {
        path: "/en/service/menu-management/api",
        component: LandingApi
    },
    {
        path: "/it/servizio/gestione-menu/api",
        component: LandingApi
    },

    {
        path: "/en/service/menu-management/widget",
        component: LandingWidget
    },
    {
        path: "/it/servizio/gestione-menu/widget",
        component: LandingWidget
    },

    {
        path: "/public/:companyname/menu/:viewId",
        component: UserPublicPage
    }

]

export default ExtendsWithSubRoutes