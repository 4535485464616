import { 
    ApolloClient, 
    InMemoryCache, 
    HttpLink,
} from '@apollo/client';
import possibleTypes from '@betwixt/my/src/possibleTypes.json'
import fetch from 'node-fetch';

require('@betwixt/common/src/config/config.js')

let httpLinkObject = { 
    uri: global.gConfig.GraphQL.uri,
    credentials: 'same-origin'
}

if (process && !process.browser)
    httpLinkObject.fetch = fetch

const httpLink = new HttpLink(httpLinkObject)
const InMemoryCacheObject = { possibleTypes }

const cache = (process && !process.browser) 
                ? new InMemoryCache(InMemoryCacheObject)
                : new InMemoryCache(InMemoryCacheObject).restore(window.__APOLLO_STATE__)

let apolloClientObject = {
    cache: cache,
    link: httpLink,
    connectToDevTools: global.gConfig.config_id === 'development'
}

if (process && !process.browser)
    apolloClientObject.ssrMode = true

if (process && process.browser)
    apolloClientObject.ssrForceFetchDelay = 100

let client = new ApolloClient(apolloClientObject);

export default client